import Translator from "./scripts/translator/Translator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import {AdvancedMarker, Map, Marker} from '@vis.gl/react-google-maps';

export default function Footer(props) {

    const defaultProps = {
        center: {
            lat: 44.43298508250304,
            lng: 26.186487610680487
        },
        zoom: 16
    }

    return (
        <footer {...props} className={"row row-cols-1 gx-0 w-100"}>
            <div className={"row row-cols-2 align-items-center justify-content-between px-5 gx-0 touch"}>
                <p className={"m-0"}><Translator path={"section.footer.get-in-touch"}/></p>
                <span>
                    <button className={`icon clickable ms-auto`}
                            onClick={() => window.open("https://www.facebook.com/AxaServiceSRL/", "_blank")}>
                        <FontAwesomeIcon icon={faFacebook}/>
                    </button>
                </span>
            </div>
            <div className={"row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-0 px-4 py-3"}>
                <div className={"col-auto title p-4"}>
                    <h3><Translator path={"section.home.title"}/></h3>
                    <hr/>
                    <span><Translator path={"section.home.subTitle"}/></span>
                </div>
                <div className={"col-auto contact p-4"}>
                    <h3><Translator path={"section.footer.contact"}/></h3>
                    <hr/>
                    <span><FontAwesomeIcon icon={faHome}/><Translator path={"text.address"}/></span>
                    <span><FontAwesomeIcon icon={faEnvelope}/><Translator path={"text.email"}/></span>
                    <span><FontAwesomeIcon icon={faPhone}/><Translator path={"text.phoneNumber"}/></span>
                </div>
                <div className={"col-12 col-md-12 col-lg-4 pt-4 h-100 map"}>
                    <Map mapId={"googlemap"} defaultZoom={defaultProps.zoom} defaultCenter={defaultProps.center}>
                        <AdvancedMarker map={"googlemap"} title={"Axa Service"} position={defaultProps.center}/>
                    </Map>
                </div>
            </div>
            <a href={"https://trufinvlad.ro/"} target={"_blank"}
               className={"col-12 copyrights"}
            >
                <Translator path={"text.copyrights"}/>
            </a>
        </footer>
    )
}